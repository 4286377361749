import React, { useState } from 'react'
import FormBoxLayout from 'src/components/FormBoxLayout'
import Form from 'src/components/Form'
import FormGroup from 'src/components/FormGroup'
import TextFieldFormGroup from 'src/components/TextFieldFormGroup'
import ActionButtonFormGroup from 'src/components/ActionButtonFormGroup'
import Link from 'src/components/Link'

function ForgotPassword () {
  const [email, setEmail] = useState<string>('')

  const handleSubmit = () => {
    alert('coming soon')
  }

  const title = 'Forgot Password'

  return (
    <FormBoxLayout title={title}>
      <Form label={title} onSubmit={handleSubmit}>
        <TextFieldFormGroup
          inputProps={{
            autoFocus: true,
            label: 'Email',
            value: email,
            placeholder: 'satoshi@example.com',
            onChange: setEmail,
            type: 'text'
          }}
        />
        <ActionButtonFormGroup buttonText={'Send reset email'} />
      </Form>
      <FormGroup align={'left'}>
        <Link href="/login">{'❮'} Login</Link>
      </FormGroup>
    </FormBoxLayout>
  )
}

export default ForgotPassword
